/* styles.css */

body {
    text-align: left;
    background-color: #02293e;
    color: #FFFFFF;
}

/* Добавляем стили для контейнера формы */
.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Выравнивание по центру */
    /* Выравнивание по центру */
    background-color: #003D4E;
    border: 1px solid #00617A;
    padding: 20px;
    border-radius: 10px;
    justify-content: center;
    text-align: center;
    /* Выравнивание текста по центру */
}

/* Стили для логотипа */
.logo {
    width: 100px;
    /* Ширина логотипа */
    height: auto;
    /* Высота подстраивается автоматически */
    margin-bottom: 20px;
    /* Отступ снизу для разделения от других элементов */
    display: block;
    /* Делаем изображение блочным элементом */
    margin-left: auto;
    /* Выравнивание по центру по горизонтали */
    margin-right: auto;
    /* Выравнивание по центру по горизонтали */
}

.text-in-container {
    text-align: left;
}

.warning-text {
    color: #F43D5A;
    font-weight: bold;
    text-transform: uppercase;

}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid #34B6B4;
}

.title {
    margin-top: 20px;
    margin-bottom: 20px;
}

.hint-text {
    text-align: center;
    padding-top: 5px;
    color: #55FFFC;
}

.errorText {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #F43D5A;
}

.btn-primary {
    margin: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #34B6B4;
    border-color: #278a89;
    color: #FFFFFF;
}

.btn-secondary {
    margin: 10px;
    padding-left: 10px;
    background-color: #003D4E;
    border-color: #34B6B4;
    color: #FFFFFF;
}

.btn-warning {
    margin: 10px;
    padding-left: 10px;
    background-color: #F43D5A;
    border-color: #A43D5A;
    color: #FFFFFF;
}


@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        margin-top: 30px;
        margin-bottom: 20px;
        animation: App-logo-breath infinite 1s linear;
    }
}


@keyframes App-logo-breath {
    0% {
        transform: scale(1);
        /* Начальный размер */
    }

    50% {
        transform: scale(1.2);
        /* Увеличение до 120% */
    }

    100% {
        transform: scale(1);
        /* Возвращение к изначальному размеру */
    }
}

.container-сarousel {
    align-items: center;
    width: 100%;
    height: 50%;
}

.card-сarousel {
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.img-step {
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #003D4E;
    border: 1px solid #00617A;
    border-radius: 10px;
    width: 50%;

}
.card img {
    width: 100%;
    border-radius: 20px;
    border-color: #55FFFC;
    border-width: 2px;
    box-shadow: 0 4px 8px rgba(16, 16, 16, 0.1);

}

.content-step {
    padding: 20px;
}

h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

p {
    font-size: 1rem;
    margin-bottom: 20px;
}

.buttons {
    display: flex;
    justify-content: space-between;
}

button {
    margin-left: 5px;
    margin-right: 5px;
    padding: 8px 16px;
    font-size: 1rem;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    background-color: #34B6B4;
    color: white;
}

button:hover {
    background-color: #0056b3;
}

/* Основной цвет ссылки */
a {
    color: #34B6B4; /* Цвет ссылки */
    text-decoration: none; /* Убрать подчеркивание у ссылки, если нужно */
  }
  
  /* При наведении курсора на ссылку */
  a:hover {
    color: #2c9b99; /* Измененный цвет при наведении */
  }